/* Profile.css */
.profile-container {
    max-width: 600px;
    margin: 50px auto; /* Increased margin for better spacing */
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .profile-box {
    text-align: center;
  }
  
  .profile-details {
    margin-top: 20px;
    text-align: left;
  }
  
  .profile-details p {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .error-message {
    color: red;
    font-weight: bold;
  }
  