.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px; /* Adjust height as needed */
    background-color: #f8f9fa; /* Optional: Set background color */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better separation */
  }
  
  .navbar h1 {
    margin: 0;
    font-size: 24px; /* Adjust font size as needed */
    color: #333; /* Optional: Set text color */
  }  