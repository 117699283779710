/* Sidebar Styles */
.sidebar {
    position: fixed; /* Fixed position to stay in place */
    top: 0;
    left: 0;
    width: 180px; /* Width of the sidebar */
    height: 100vh; /* Full viewport height */
    background-color: #343a40;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Pushes the logout button to the bottom */
    box-sizing: border-box; /* Ensure padding is included in height calculation */
  }
  
  /* Sidebar Navigation */
  .sidebar nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .sidebar nav ul li {
    margin-bottom: 10px;
  }
  
  .sidebar nav ul li a {
    color: #ffffff;
    text-decoration: none;
  }
  
  .sidebar nav ul li a:hover {
    color: #adb5bd;
  }
  
  /* Logout Button Styles */
  .logout-button {
    margin-top: auto; /* Pushes the button to the bottom of the sidebar */
    padding-bottom: 20px; /* Adds space below the button */
  }
  
  .logout-button button {
    width: 100%; /* Full width of the sidebar */
    padding: 10px;
    background-color: #dc3545;
    color: #ffffff;
    border: none;
    cursor: pointer;
    text-align: center;
  }
  
  .logout-button button:hover {
    background-color: #c82333;
  }
  