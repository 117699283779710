/* src/pages/Network.css */

.network-container {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    text-align: center;
  }
  
  .network-box-container {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    margin-top: 20px;
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
  }
  
  .network-box {
    flex: 1; /* This allows the box to take equal space */
    min-width: 300px; /* Ensures a minimum size */
    max-width: 500px; /* Ensures a maximum size */
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
  }
  
  .list-box {
    max-height: 300px;
    overflow-y: auto;
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 10px;
  }
  
  .list-header {
    display: flex;
    justify-content: center;
    font-weight: bold;
    padding: 10px;
    background-color: #e0e0e0;
    border-bottom: 1px solid #ccc;
  }
  
  .list-item {
    display: flex;
    justify-content: center;
    padding: 10px;
    border-bottom: 1px solid #eee;
  }
  
  .list-item span,
  .list-header span {
    width: 50%;
    text-align: center; /* Center-align text */
  }
  
  .error-message {
    color: red;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* Styles for the export button */
  .export-button-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  
  .export-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .export-button:hover {
    background-color: #0056b3;
  }
  