/* Reports.css */

.reports-container {
    max-width: 100%; /* Use full screen width */
    margin: 0 auto;
    padding: 20px;
}

h1 {
    text-align: center;
    margin-bottom: 20px;
}

.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.tabs button {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.tabs button.active {
    background-color: #007bff;
    color: #fff;
}

.tabs button:hover:not(.active) {
    background-color: #ddd;
}

.date-filters {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;
}

.date-filters label {
    font-size: 14px;
    display: flex;
    flex-direction: column;
}

.date-filters input {
    padding: 5px;
    font-size: 14px;
}

.reports-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.report-box {
    width: 95%; /* Expand the width further to take more space */
    max-width: 100%; /* Allow box to grow to the full screen width if necessary */
    margin: 20px auto; /* Center the report box */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    position: relative; /* Allow for positioning of the export button */
  }
  
  
.report-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
.export-button {
    padding: 8px 12px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    font-size: 14px;
  }
  
.export-button:hover {
    background-color: #45a049;
  }


/* Table styling */
.report-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.report-table th, .report-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.report-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.report-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.report-table tbody tr:hover {
    background-color: #f1f1f1;
}
